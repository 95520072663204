define([
  'jquery',
  'modules/shop.cash-register-retail/controllers/default/main',
  'modules/shop.cash-register-retail/views/repairs/overview/layout',
  'modules/shop.cash-register-retail/views/repairs/details/layout',
  'modules/shop.cash-register-retail/views/repairs/create',

  'upx.modules/ShopModule/models/Repair',
  'upx.modules/ShopModule/collections/RepairQuestion',
  'upx.modules/ShopModule/collections/RepairQuestionOption',
],
(
  $, DefaultController, ListView, DetailsView, CreateView,
  RepairModel, RepairQuestionCollection, RepairQuestionOptionCollection,
) => DefaultController.extend({

  list() {
    this.getRegionByPath('main.page.content').show(new ListView());
  },

  create() {
    this.getRegionByPath('main.page.content').show(new CreateView());

    const def = this.handleLoadingView(CreateView);
    const repairQuestionCollection = new RepairQuestionCollection();
    const repairQuestionCollectionParams = {
      params: {
        start: 0,
        limit: 250,
        sort: [{
          name: 'sequence_order',
          dir: 'asc',
        }, {
          name: 'question',
          dir: 'asc',
        }],
        filters: [{
          name: 'is_active__=',
          val: true,
        }],
      },
    };

    const repairQuestionOptionCollection = new RepairQuestionOptionCollection();
    const repairQuestionOptionCollectionParams = {
      params: {
        start: 0,
        limit: 250,
        sort: [{
          name: 'sequence_order',
          dir: 'asc',
        }],
        filters: [{
          name: 'repair_question/is_active__=',
          val: 1,
        }],
      },
    };

    $.when(
      repairQuestionCollection.fetchAll(repairQuestionCollectionParams),
      repairQuestionOptionCollection.fetchAll(repairQuestionOptionCollectionParams),
    ).then(() => {
      repairQuestionCollection.map((questionModel) => {
        const options = repairQuestionOptionCollection.filter((optionModel) => optionModel.get('question_id') == questionModel.get('id')).map((model) => model.toJSON());
        questionModel.set({ options });
      });
      def.resolve({
        repairQuestionCollection,
      });
    }, def.reject);
  },

  get(id) {
    const def = this.handleLoadingView(DetailsView);
    const model = new RepairModel({ id });
    model.fetch().then(() => {
      def.resolve({
        model,
      });
    }, def.reject);
  },
}));
