define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/models/ipc/ipc',
  'modules/shop.cash-register-retail/models/settings/terminal',
  'modules/shop.cash-register-retail/models/settings/receiptPrinter',

  'upx.modules/PaymentModule/models/Payment',
  './openCCVPinTransaction',
], (
  $, _, Backbone, Locale, IPC, TerminalSetting, ReceiptPrinter,
  PaymentModel, OpenCCVPinTransaction,
) => Backbone.Model.extend({

  RESULT_SUCCESS: 'Success',
  RESULT_CANCELLED: 'Aborted',
  RESULT_TIMEOUT: 'TimedOut',
  RESULT_UNAVAILABLE: 'DeviceUnavailable',

  EVENT_OUTPUT: 'output',
  EVENT_OUTPUT_CASHIER_DISPLAY: 'output:cashier-display',
  EVENT_FINISHED: 'finished',
  EVENT_UNKNOWN_RESULT: 'unknown-result',
  EVENT_IPC_CONNECTION_LOST: 'ipc:connection-lost',
  EVENT_FORCE_CANCEL: 'force-cancel',
  EVENT_CANCEL_FAILED: 'cancel-failed',

  initialize(options) {
    this.trx = options.trx;
    this.hasStarted = false;
  },

  addIPCListeners() {
    IPC.ipcOn('payment_device_output', async (data) => {
      if (data.trx === this.trx) {
        this.trigger(this.EVENT_OUTPUT, data);

        if (data.output.target === 'CashierDisplay') {
          const { textLines } = data.output;

          const line1 = textLines[0];
          const line2 = textLines[1];
          const line1Text = line1 ? line1.text : '';
          const line2Text = line2 ? line2.text : '';

          this.trigger(this.EVENT_OUTPUT_CASHIER_DISPLAY, {
            line1: line1Text,
            line2: line2Text,
          });
        }
      }
    });

    IPC.ipcOn('payment_finished', (data) => {
      if (data.trx === this.trx) {
        this.trigger(this.EVENT_FINISHED, data.result);
      }
    });

    IPC.ipcOn('payment_unknown_result', (data) => {
      if (data.trx === this.trx) {
        this.trigger(this.EVENT_UNKNOWN_RESULT);
      }
    });

    IPC.on('change:available', () => {
      if (!IPC.isAvailable()) {
        // We lost connection to IPC :(
        this.trigger(this.EVENT_IPC_CONNECTION_LOST);
      }
    });
  },

  removeIPCListeners() {
    IPC.ipcOff('payment_device_output');
    IPC.ipcOff('payment_finished');
    IPC.ipcOff('payment_unknown_result');
    IPC.off('change:available');
  },

  startPayment({
    payment, cashierDisplay, orderId, invoiceId,
  }) {
    const def = $.Deferred();
    const logPrefix = `[CCVPinPayment ID:${payment.id}]`;

    if (!IPC.isAvailable()) {
      console.warn(`${logPrefix} Could not start payment, IPC is not available`);
      return def.reject({
        error: Locale.translate('could_not_start_ccv_pin_payment'),
      });
    }

    ReceiptPrinter.isPrinterAvailable().then((isAvailable) => {
      const printerStatus = ReceiptPrinter.isWantedType() && isAvailable ? 'Available' : 'Unavailable';

      const data = {
        trx: this.trx,
        id: payment.id,
        orderId,
        invoiceId,
        amount: payment.amount,
        currency: payment.currency_iso3,
        hookUpdateUrl: payment.metadata.hook_update_url,
        printerStatus,
      };

      this.addIPCListeners();
      IPC.ipcSend('payment_start', data).then(() => {
        console.debug(`${logPrefix} Payment started`);
        this.hasStarted = true;
        def.resolve();

        if (cashierDisplay) {
          // Payment has been started, so show the abort button
          cashierDisplay.toggleCancelButton(true);

          cashierDisplay.onCancel(() => {
            const cancelDef = new $.Deferred();

            this.cancelPayment().then((forceCancel) => {
              cancelDef.resolve();

              // If force cancel is true, it means the transaction won't finish by itself.
              // So we need to "force" cancel the transaction.
              if (forceCancel) {
                this.trigger(this.EVENT_FORCE_CANCEL);
              }
            }, (error) => {
              console.error(`${logPrefix} Failed to cancel payment`, error);

              // When the cancelling of a payment fails,
              // it probably means no new connection to the pin terminal could be made.
              this.trigger(this.EVENT_CANCEL_FAILED);
              OpenCCVPinTransaction.loadOpenTransaction();

              cancelDef.reject();
            });

            return cancelDef;
          });

          this.on(this.EVENT_OUTPUT_CASHIER_DISPLAY, ({ line1, line2 }) => {
            if (cashierDisplay) {
              cashierDisplay.updateDisplay(line1, line2);
            }
          });

          this.on(this.EVENT_FINISHED, () => {
            this.removeIPCListeners();
          });
        }
      }).catch((error) => {
        this.removeIPCListeners();
        if (error && error.name === 'CCVError') {
          if (error.code === 'device_unreachable') {
            console.warn(`${logPrefix} Could not start payment, device is unreachable`);
            return def.reject({
              error: Locale.translate('pin_terminal_could_not_be_reached_is_it_online_question'),
            });
          }
        }

        return def.reject({
          error: Locale.translate('could_not_start_ccv_pin_payment'),
        });
      });
    }, def.reject);

    return def;
  },

  cancelPayment() {
    const def = $.Deferred();
    const logPrefix = '[CCVPinPayment]';

    if (!this.hasStarted) {
      console.warn(`${logPrefix} Tried to cancel unstarted payment (trx=${this.trx})`);
      return def.reject({
        error: Locale.translate('there_is_no_payment_to_cancel'),
      });
    }

    IPC.ipcSend('payment_abort').then((forceCancel) => {
      console.debug(`${logPrefix} Payment cancelled (forceCancel=${forceCancel})`);
      def.resolve(forceCancel);
    }).catch(() => {
      def.reject({
        error: Locale.translate('could_not_cancel_ccv_payment'),
      });
    });

    return def;
  },

  startRecovery(cashierDisplay, ignoreStarted = false) {
    const def = $.Deferred();
    const logPrefix = '[CCVPinPayment]';

    if (!this.hasStarted && !ignoreStarted) {
      console.warn(`${logPrefix} Tried to recover unstarted payment (trx=${this.trx})`);
      return def.reject({
        error: Locale.translate('there_is_no_payment_to_recover'),
      });
    }

    IPC.ipcOn('payment_recovery_update', async ({ trx, recoveryUpdate }) => {
      if (trx !== this.trx) return;

      let line1 = '';
      let line2 = '';
      if (recoveryUpdate.step === 'get_last_transaction') {
        line1 = Locale.translate('retrieving_last_transaction');
        line1 += ` (${recoveryUpdate.currentTry}/${recoveryUpdate.maxTries})`;
      } else if (recoveryUpdate.step === 'reprint_last_ticket') {
        line1 = Locale.translate('retrieving_last_pin_receipt');
      }

      if (recoveryUpdate.updateKey === 'connect_failed') {
        line2 = Locale.translate('could_not_connect_to_pin_device');
      } else if (recoveryUpdate.updateKey === 'failed') {
        line2 = Locale.translate('failure');
      }

      cashierDisplay.updateDisplay(
        line1.toUpperCase(),
        line2.toUpperCase(),
      );
    });

    // Start the recovery
    IPC.ipcSend('payment_start_recovery', {
      originalTrx: this.trx,
    }).then((result) => {
      console.debug(`${logPrefix} Transaction recovered`, result);

      def.resolve(result);
    }).catch((err) => {
      console.error(`${logPrefix} Could not recover transaction`, err);

      // Reload the current open transaction.
      OpenCCVPinTransaction.loadOpenTransaction();

      def.reject({
        error: Locale.translate('could_not_recover_transaction'),
      });
    });

    return def;
  },

  cancelRecovery() {
    const def = $.Deferred();
    const logPrefix = '[CCVPinPayment]';

    // Cancel the recovery
    IPC.ipcSend('payment_cancel_recovery').then(() => {
      console.debug(`${logPrefix} Transaction recovery cancelled`);
      def.resolve();
    }).catch((err) => {
      console.error(`${logPrefix} Could not cancel transaction recovery`, err);
      def.reject({
        error: Locale.translate('could_not_cancel_transaction_recovery'),
      });
    });

    return def;
  },

  verifyPayment(
    paymentId,
    def = new $.Deferred(),
    errorTries = 0,
  ) {
    const logPrefix = `[CCVPinPayment ID:${paymentId}]`;

    const maxRetries = 15;
    const retryWait = 1000;
    const expectedStatus = 'paid';

    console.debug(`${logPrefix} Verifying payment (${errorTries + 1}/${maxRetries})`);

    if (errorTries >= maxRetries - 1) {
      console.error(`${logPrefix} Failed to verify payment`);
      return def.reject({
        error: Locale.translate('failed_to_verify_the_payment'),
      });
    }

    const retry = () => {
      setTimeout(() => {
        this.verifyPayment(paymentId, def, errorTries + 1);
      }, retryWait);
    };

    const paymentModel = new PaymentModel({ id: paymentId });
    paymentModel.fetch().then((payment) => {
      if (payment.status === expectedStatus) {
        console.debug(`${logPrefix} Verified payment`);
        def.resolve(payment);
      } else {
        console.warn(`${logPrefix} Payment is not at expected state, retrying... (expected=${expectedStatus}, actual=${payment.status})`);
        retry();
      }
    });

    return def;
  },
}));
