define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/controllers/default/main',

  'modules/shop.cash-register-retail/views/paymentScreens/invoice/swappable',
  'modules/shop.cash-register-retail/views/paymentScreens/order/swappable',
  'modules/shop.cash-register-retail/views/popups/memberCardListPopup',

  'upx.modules/BillingModule/models/Invoice',
  'upx.modules/BillingModule/models/InvoiceStat',

  'modules/shop.cash-register-retail/models/upx/Order',
  'modules/shop.cash-register-retail/models/customer',
  'modules/shop.cash-register-retail/models/upx/LoyaltyProgram',

  'modules/shop.cash-register-retail/collections/routeCache',

  'modules/shop.cash-register-retail/components/customer',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/collections/TaxRate.js',
],
(
  $, _, Backbone, DefaultController,
  InvoicePaymentScreen, OrderPaymentScreen, MemberCardListPopup,
  InvoiceModel, InvoiceStatModel,
  OrderModel, CustomerModel, LoyaltyProgramModel,
  RouteCache,
  Customer, Locale, TaxRateCollection,
) => DefaultController.extend({

  invoicePayment(cacheId) {
    const def = this.handleLoadingView(InvoicePaymentScreen);
    const cacheData = RouteCache.getCache(cacheId, {});
    const { invoiceId = null } = cacheData;

    if (invoiceId) {
      this.handleSingleInvoice(def, invoiceId);
    } else {
      def.reject({
        error: Locale.translate('no_invoice_found'),
      });
    }
  },

  orderPayment(cacheId) {
    const def = this.handleLoadingView(OrderPaymentScreen);
    const cacheData = RouteCache.getCache(cacheId, {});
    const { orderId = null, backRoute = null } = cacheData;

    if (orderId) {
      this.handleSingleOrder(def, orderId, { backRoute });
    } else {
      def.reject({
        error: Locale.translate('no_order_found'),
      });
    }
  },

  handleSingleOrder(def, orderId, options = {}) {
    const model = new OrderModel({ id: orderId });
    $.when(
      model.loadOrderItems(),
      TaxRateCollection.load(),
    ).then(
      () => {
        model.set(
          'value_outstanding_wt',
          model.get('value_wt') - model.get('paid_value_wt')
            + model.get('paid_back_value_wt'),
        );

        const customerModel = new CustomerModel();
        const resolveData = { model, customerModel, ...options };
        if (model.get('is_anonymous')) {
          def.resolve(resolveData);
        } else {
          const relation_data_id = model.get('relation_data_id');
          $.when(
            customerModel.selectByRelationDataId(relation_data_id),
            LoyaltyProgramModel.loadIfActive(),
          ).then((memberCardResponse) => {
            $.when(this.ensureLoyaltyStats(customerModel)).then(() => {
              this.applyMembercardData(memberCardResponse)
                .then((memberCardModel) => {
                  if (memberCardModel) {
                    customerModel.set({
                      active_membercard: memberCardModel.get('active'),
                      notes_membercard: memberCardModel.get('notes'),
                      code_membercard: memberCardModel.get('code'),
                      due_date_membercard: memberCardModel.get('due_date_inclusive'),
                    });
                  }

                  def.resolve(resolveData);
                }, def.reject);
            }, def.reject);
          }, def.reject);
        }
      }, def.reject,
    );
  },
  handleSingleInvoice(def, invoiceId) {
    const model = new InvoiceModel({
      id: invoiceId,
      get_rows: true,
      get_taxes: true,
    });
    model.fetch()
      .then(() => {
        const relation_data_id = model.get('relation_data_to_id');
        const invoiceStatModel = new InvoiceStatModel();
        const parameters = {
          start: 0,
          limit: 1,
          filters: [{
            name: 'relation_data_to_id__=',
            val: relation_data_id,
          }],
        };

        $.when(
          Customer.getFullInformation({ relation_data_id }),
          Customer.getMemberCard({ relation_data_id }),
          invoiceStatModel.listByRelationTo(parameters),
          TaxRateCollection.load(),
          LoyaltyProgramModel.loadIfActive(),
        ).then((customerModel, memberCardResponse, invoiceStatResp) => {
          $.when(this.ensureLoyaltyStats(customerModel)).then(() => {
            this.applyMembercardData(memberCardResponse)
              .then((memberCardModel) => {
                if (memberCardModel) {
                  customerModel.set({
                    active_membercard: memberCardModel.get('active'),
                    notes_membercard: memberCardModel.get('notes'),
                    code_membercard: memberCardModel.get('code'),
                    due_date_membercard: memberCardModel.get('due_date_inclusive'),
                  });
                }

                const { data } = invoiceStatResp;
                invoiceStatModel.set(_.first(data));

                def.resolve({
                  model, customerModel, invoiceStatModel, loyaltyProgramModel: LoyaltyProgramModel,
                });
              }, def.reject);
          },
          def.reject);
        }, def.reject);
      }, def.reject);
  },

  applyMembercardData(memberCardResponse) {
    const def = new $.Deferred();

    if (memberCardResponse) {
      const { model = null, collection = null } = memberCardResponse;
      if (model) {
        def.resolve(model);
      } else if (collection) {
        (new MemberCardListPopup())
          .open(collection)
          .then((cardModel) => def.resolve(cardModel), () => def.resolve());
      } else {
        def.resolve();
      }
    } else {
      def.resolve();
    }

    return def;
  },

  ensureLoyaltyStats(customerModel) {
    const def = $.Deferred();

    if (!LoyaltyProgramModel.isActive() || customerModel.has('loyalty_customer')) {
      def.resolve();
    } else {
      LoyaltyProgramModel.fetchLoyaltyCustomer(customerModel.get('id')).then(
        (data) => {
          customerModel.set('loyalty_customer', data);

          def.resolve();
        },
        def.reject,
      );
    }

    return def;
  },

}));
