define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/controllers/default/main',
], (
  $, _, Backbone, DefaultController,
) =>
  // This controller is used to redirect people to a page. This is used for reloading certain pages
  // If you want to reload the page when you click on the menu item. you can use this view.
  DefaultController.extend({

    reload() {
      // Because you can not call arguments.join('/') this piece of code is needed
      const argumentArray = [];
      // First I'm looping over all items in arguments.
      for (let i = 0; i < arguments.length; i++) {
        // And if the value is not falsy, it adds it to the argumentArray.
        if (arguments[i]) {
          argumentArray.push(arguments[i]);
        }
      }
      // Where after I call .join on the argumentsArray, and get the path
      const path = argumentArray.join('/');

      // setTimeout is needed to make the page "reload"
      setTimeout(() => {
        Backbone.history.navigate(path, { trigger: true });
      });
    },

  }));
