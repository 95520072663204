define([
  'backbone',
  'modules/shop.cash-register-retail/controllers/default/main',
  'modules/shop.cash-register-retail/views/newsLetters/swappable',
],
(
  Backbone, DefaultController, NewsLettersSwappableView,
) => DefaultController.extend({

  overview() {
    this.getRegionByPath('main.page.content').show(new NewsLettersSwappableView());
  },

  reload() {
    this.getRegionByPath('main.page.content').reset();
    // So the change does not happen to quick
    setTimeout(() => {
      Backbone.history.navigate('news-letters', { trigger: true });
    });
  },

}));
