define([
  'backbone',
  'modules/shop.cash-register-retail/controllers/default/main',
  'modules/shop.cash-register-retail/views/openOrders/list/layout',
  'modules/shop.cash-register-retail/collections/upx/ShippingType.js',
],
(
  Backbone, DefaultController, OpenOrderListView, ShippingType,
) => DefaultController.extend({

  orders(orderId) {
    const def = this.handleLoadingView(OpenOrderListView);
    ShippingType.load().then(
      () => {
        def.resolve({ orderId });
      },
      def.reject,
    );
  },

  reload() {
    this.getRegionByPath('main.page.content').reset();
    // So the change does not happen to quick
    setTimeout(() => {
      Backbone.history.navigate('open-orders', { trigger: true });
    });
  },
}));
