define([
  'backbone',
  'modules/shop.cash-register-retail/controllers/default/main',
  'modules/common/components/locale',

  'modules/shop.cash-register-retail/views/posSettings/swappable',
],
(
  Backbone, DefaultController, Locale,
  SettingsLayout,
) => DefaultController.extend({

  settings() {
    this.getRegionByPath('main.page.content')
      .show(new SettingsLayout());
  },

  reload() {
    Backbone.history.navigate('pos-settings', { trigger: true });
  },

}));
