define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/models/ipc/ipc',
  'modules/shop.cash-register-retail/models/settings/terminal',
], (
  $, _, Backbone, Locale, IPC, TerminalSetting,
) => {
  const Model = Backbone.Model.extend({

    hasOpenTransaction() {
      return this.has('transaction');
    },

    getOpenTransaction() {
      return this.get('transaction');
    },

    loadOpenTransaction() {
      const def = $.Deferred();

      if (IPC.isAvailable() && TerminalSetting.isCCVPin()) {
        IPC.ipcSend('payment_get_open_transaction').then((transaction) => {
          const terminalId = TerminalSetting.get('id');
          if (transaction && transaction.terminal.id === terminalId) {
            this.setTransaction(transaction);
            def.resolve(transaction);
          } else {
            this.removeOpenTransaction();

            def.resolve(undefined);
          }
        }).catch(def.reject);
      } else {
        def.resolve(undefined);
      }

      return def;
    },

    removeOpenTransaction() {
      this.setTransaction(undefined);
    },

    setTransaction(transaction) {
      this.set('transaction', transaction);
      this.trigger('transaction:changed');
    },
  });

  return new Model();
});
