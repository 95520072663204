define([
  'modules/common/crontabs/cron',
  'modules/common/components/idle',
  'modules/common/components/moment',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
],
(Cron, Idle, Moment, CashRegisterApi) => Cron.extend({
  cron: '* * * * *', // every minute

  lastKnownActive: new Date(),

  run() {
    const lastActive = Idle.getLastActive();

    const data = new FormData();
    data.append('last_activity', new Moment(lastActive).format());

    CashRegisterApi.call('/activity', 'post', data);
  },

}));
