define([
  'modules/common/crontabs/cron',
  'modules/shop.cash-register-retail/models/connection',
],
(Cron, ConnectionModel) => Cron.extend({
  cron: '*/10 * * * * *',

  run() {
    ConnectionModel.checkConnection();
    ConnectionModel.getConnectionStatistics().then((stats) => {
      let connectionQuality = ConnectionModel.connectionQualityBad;
      if (stats.percentageFailed === 0) {
        connectionQuality = ConnectionModel.connectionQualityGood;
      } else if (stats.percentageFailed < 5) {
        connectionQuality = ConnectionModel.connectionQualityMedium;
      }
      ConnectionModel.set('connectionQuality', connectionQuality);
    }, (error) => {
      console.error('An error occured while fetching the connection statistics', [error]);
    });
  },
}));
