define([
  'jquery',
  'underscore',
  'backbone',

  'modules/admin/controllers/default',
  'modules/shop.cash-register-retail/views/layouts/fullscreen/layout',
], (
  $, _, Backbone,
  Controller, Layout,
) => Controller.extend({

  mainLayout: Layout,

  mainLayoutOptions: {

  },

  before() {
    this.renderLayout([{
      regionName: 'main',
      layoutView: this.mainLayout,
      options: this.mainLayoutOptions,
    }]);
  },

}));
