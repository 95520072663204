define([
  'modules/shop.cash-register-retail/controllers/default/fullscreen',
  'modules/common/components/locale',

  'modules/shop.cash-register-retail/views/customerScreens/layout',
],
(
  DefaultController, Locale,
  SettingsLayout,
) => DefaultController.extend({

  customerScreen() {
    const contentRegion = this.getRegionByPath('main.page');

    contentRegion.show(new SettingsLayout());
  },

}));
