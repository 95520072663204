define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/models/ipc/ipc',
  'modules/shop.cash-register-retail/models/settings/terminal',
  'modules/shop.cash-register-retail/models/settings/paymentMethods',
  'modules/shop.cash-register-retail/components/printing',

  'modules/shop.cash-register-retail/collections/upx/PaymentProvider',
  'modules/shop.cash-register-retail/views/popups/openCCVPinTransactionPopup',
  './openCCVPinTransaction',
], (
  $, _, Backbone, Locale, IPC, TerminalSetting, PaymentMethodsSetting, Printing,
  PaymentProviderCollection, OpenCCVPinTransactionPopup, OpenCCVPinTransaction,
) => {
  const Model = Backbone.Model.extend({
    logPrefix: '[CCVPin]',

    isAvailable() {
      return IPC.isAvailable();
    },

    setupTerminal(id, name, ipAddress) {
      const def = $.Deferred();

      if (!IPC.isAvailable()) {
        console.warn(`${this.logPrefix} Could not setup terminal, IPC is not available`);
        return def.reject({
          error: Locale.translate('could_not_set_up_ccv_pin_terminal'),
        });
      }

      IPC.ipcSend('payment_setup_pin_terminal', {
        id,
        name,
        ipAddress,
      }).then(() => {
        console.debug(`${this.logPrefix} Terminal setup (id=${id}, name=${name}, ipAddress=${ipAddress})`);
        this.ready = true;
        def.resolve();
      }).catch((error) => {
        console.error(`${this.logPrefix} Could not setup terminal`, error);
        def.reject({
          error: Locale.translate('could_not_set_up_ccv_pin_terminal'),
        });
      });

      return def;
    },

    removePinTerminal() {
      const def = $.Deferred();

      if (!this.isAvailable()) {
        console.warn(`${this.logPrefix} Could not remove terminal, IPC is not available`);
        return def.reject({
          error: Locale.translate('could_not_remove_ccv_pin_terminal'),
        });
      }

      IPC.ipcSend('payment_remove_pin_terminal').then(() => {
        console.debug(`${this.logPrefix} Terminal removed`);
        OpenCCVPinTransaction.removeOpenTransaction();
        this.ready = false;
        def.resolve();
      }).catch(() => {
        def.reject({
          error: Locale.translate('could_not_remove_ccv_pin_terminal'),
        });
      });

      return def;
    },

    updatePinTerminal() {
      const def = $.Deferred();

      if (this.isAvailable() && TerminalSetting.isCCVPin()) {
        TerminalSetting.reload();

        const id = TerminalSetting.get('id');
        const ipAddress = TerminalSetting.get('extra.local_ip');
        const name = TerminalSetting.get('title');

        this.setupTerminal(id, name, ipAddress).then(() => {
          OpenCCVPinTransaction.loadOpenTransaction().then(() => def.resolve(), def.reject);
        }, def.reject);
      } else {
        def.resolve();
      }

      return def;
    },

    printLastPinReceipt() {
      const def = $.Deferred();
      const logPrefix = '[CCVPinPayment]';

      if (this.isAvailable() && TerminalSetting.isCCVPin()) {
        // Cancel the recovery
        IPC.ipcSend('payment_print_last_receipt').then((lastReceipts) => {
          console.debug(`${logPrefix} Received pin receipts`, lastReceipts);
          Printing.printCCVReceipt(lastReceipts)
            .then(() => {
              console.debug(`${logPrefix} Printed pin receipts`, lastReceipts);
              def.resolve(lastReceipts);
            }, def.reject);
        }).catch((error) => {
          if (error.code === 'device_unreachable') {
            def.reject({
              error: Locale.translate('pin_terminal_could_not_be_reached_is_it_online_question'),
            });
          } else {
            def.reject({
              error: Locale.translate('could_not_print_last_pin_receipt'),
            });
          }
        });
      } else {
        def.reject({
          error: Locale.translate('pin_terminal_is_not_available'),
        });
      }

      return def;
    },

    getLastTransaction() {
      const def = $.Deferred();

      if (this.isAvailable() && TerminalSetting.isCCVPin()) {
        IPC.ipcSend('payment_get_last_transaction').then((lastTransaction) => {
          if (lastTransaction) {
            def.resolve(lastTransaction);
          } else {
            def.reject({
              error: Locale.translate('there_is_no_last_transaction_to_retrieve'),
            });
          }
        }).catch(() => {
          def.reject({
            error: Locale.translate('could_not_retrieve_last_pin_transaction'),
          });
        });
      } else {
        def.reject({
          error: Locale.translate('pin_terminal_is_not_available'),
        });
      }

      return def;
    },

    load() {
      const def = $.Deferred();

      if (this.hasCCVTerminalInTerminalCollection()) {
        this.updatePinTerminal().then(() => {
          OpenCCVPinTransaction.loadOpenTransaction().then((transaction) => {
            if (transaction) {
              this.openOpenTransactionPopup();
            }

            def.resolve();
          }, def.reject);
        }, def.reject);
      } else {
        console.debug(`${this.logPrefix} There is no CCV pin terminal, not loading...`);
        def.resolve();
      }

      return def;
    },

    /**
     * We should only load CCV pin when there is a CCV pin terminal in the terminal collection.
     * @returns boolean
     */
    hasCCVTerminalInTerminalCollection() {
      const collection = PaymentMethodsSetting.getTerminalDevicesCollection();
      return collection.any((model) => model.get('extra.protocol') === PaymentProviderCollection.TYPE_ALIAS_CCV_PIN_ATTENDED_OPI);
    },

    openOpenTransactionPopup() {
      const popup = new OpenCCVPinTransactionPopup({
        transaction: OpenCCVPinTransaction.getOpenTransaction(),
      });
      popup.open();
    },
  });

  return new Model();
});
