define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/controllers/default/main',

  'modules/shop.cash-register-retail/views/tables/overview/layout',

  'modules/shop.cash-register-retail/collections/loadable/tables',
  'modules/shop.cash-register-retail/collections/loadable/tableAreas',
  'modules/shop.cash-register-retail/collections/loadable/productionGroups',
], (
  $, _, Backbone, DefaultController,
  TablesLayoutView,
  Tables, TableAreas, ProductionGroups,
) => DefaultController.extend({

  async overview() {
    const def = this.handleLoadingView(TablesLayoutView);
    try {
      await Promise.all([
        Tables.loadPromise({ reload: true }),
        TableAreas.loadPromise({ reload: true }),
        ProductionGroups.loadPromise({ reload: true }),
      ]);

      def.resolve();
    } catch (e) {
      def.reject(e);
    }
  },

}));
