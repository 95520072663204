define([
  'jquery',
  'backbone',

  'modules/shop.cash-register-retail/controllers/default/main',
  'modules/common/components/historyBreadcrumb',
  'modules/common/components/locale',

  'modules/shop.cash-register-retail/views/customers/search',
  'modules/shop.cash-register-retail/views/customers/swappable',
  'modules/shop.cash-register-retail/views/customers/create',

  'modules/shop.cash-register-retail/models/selectedCustomer',
  'upx.modules/RelationsModule/collections/FullInfoRelation',
  'upx.modules/RelationsModule/models/FullInfoRelation',
],
(
  $, Backbone,
  DefaultController, HistoryBreadcrumb, Locale,
  SearchLayout, DetailsView, AddLayout,
  SelectedCustomer,
  FullInfoRelationCollection, FullInfoRelationModel,
) => DefaultController.extend({

  search() {
    const def = this.handleLoadingView(SearchLayout);
    def.resolve();
  },

  details(id, swapTo) {
    const def = this.handleLoadingView(DetailsView);
    this.fetchCustomer(id)
      .then((customerModel) => {
        def.resolve({
          model: customerModel,
          swapTo,
          swappablePath: `customers/details/${id}`,
        });
      });
  },

  create() {
    const contentRegion = this.getRegionByPath('main.page.content');

    contentRegion.show(new AddLayout({
      is_private: true,
    }));
  },

  createCompany() {
    const contentRegion = this.getRegionByPath('main.page.content');

    contentRegion.show(new AddLayout({
      is_private: false,
    }));
  },

  fetchCustomer(id) {
    const def = new $.Deferred();

    if (SelectedCustomer.get('id') === parseInt(id, 10)) {
      const model = new FullInfoRelationModel(SelectedCustomer.toJSON());
      def.resolve(model);
    } else {
      const collection = new FullInfoRelationCollection();
      const params = {
        start: 0,
        limit: 1,
        filters: [{
          name: 'id__=',
          val: id,
        }],
      };
      collection.fetch({ params })
        .then(() => {
          if (collection.length === 1) {
            def.resolve(collection.first());
          } else {
            def.reject({ error: Locale.translate('no_customer_found_with_id_{id}', { id }) });
          }
        }, def.reject);
    }

    return def;
  },

}));
