define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/controllers/controller',
  'modules/admin/views/login/layout',
  'modules/shop.cash-register-retail/views/login/swappable',
  'modules/upx/collections/users',
  'modules/common/components/historyBreadcrumb',
  'modules/shop.cash-register-retail/views/login/swappable',
], (
  $, _, Backbone, Controller, LoginLayout, LoginView, UserCollection, HistoryBreadcrumb, SwappableView,
) => Controller.extend({

  logout() {
    if (UserCollection.hasActiveUser()) {
      const user = UserCollection.getActiveUser();
      user.logout()
        .then(() => {
          Backbone.history.navigate('auth', { trigger: true });
        });
    } else {
      Backbone.history.navigate('auth', { trigger: true });
    }
  },

  authentication(swapTo) {
    this.renderLayout([
      { regionName: 'main', layoutView: LoginLayout },
    ]);

    const self = this;
    const renderView = function (swapTo) {
      self.getRegionByPath('main.loginContainer').show(new LoginView({
        swapTo,
        swappablePath: 'auth',
      }));
    };

    if (swapTo === 'signin') {
      swapTo = null;
    }

    if (swapTo === 'logout' && UserCollection.hasActiveUser()) {
      const user = UserCollection.getActiveUser();
      user.logout()
        .then(() => {
          renderView(null);
        });
    } else {
      renderView(null);
    }
  },

  loginByHash(data) {
    this.renderLayout([
      { regionName: 'main', layoutView: LoginLayout },
    ]);
    this.getRegionByPath('main.loginContainer').show(new SwappableView({
      swapTo: 'loginByHash',
      data,
    }));
  },

}));
